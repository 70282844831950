<template>
  <div>
    <v-form v-model="component.accountingForm">
      <v-col>
        <v-select
          label="*Plano de Contas"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialog"
          v-model="component.plan"
          :readonly="component.justVisible"
          :items="this.component.accounting_plan"
          return-object
          item-text="description"
          outlined
          :rules="[() => !!component.plan || 'Campo Obrigatorio']"
        ></v-select>
      </v-col>
    </v-form>
    <AccountPlans />
  </div>
</template>

<script>
import { mdiPlusCircle } from "@mdi/js";
import AccountPlans from "../../../account_plans/AddDialog/index.vue";

export default {
  props: {
    component: Object,
  },
  components: {
    AccountPlans,
  },
  methods: {
    showDialog() {
      this.$store.dispatch("setShowDialogRegisterAccountPlan",true)
    },
  },
  computed:{},
  data() {
    return {
      icons: [mdiPlusCircle],
    };
  },
};
</script>

<style>
</style>