<template>
  <div>
    <v-form v-model="component.generateDataForm">
      <v-col>
        <v-select
        :readonly="component.justVisible"
          label="Status"
          disabled
          :items="status"
          v-model="component.object.status"
          outlined
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
        :readonly="component.justVisible"
          label="*Data Emissao"
          v-model="component.object.issuance_date"
          type="date"
          outlined
          required
          :rules="[
            () => !!component.object.issuance_date || 'Campo Obrigatorio',
            () =>
              (!!component.object.issuance_date &&
                component.object.issuance_date.length >= 2) ||
              'Campo tem que ser maior do que 2',
          ]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        :readonly="component.justVisible"
          label="*Data Vencimento"
          v-model="component.object.issuance_due"
          type="date"
          outlined
          required
          :rules="[
            () => !!component.object.issuance_due || 'Campo Obrigatorio',
            () =>
              (!!component.object.issuance_due &&
                component.object.issuance_due.length >= 0) ||
              'Campo tem que ser maior do que 0',
          ]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
        :readonly="component.justVisible"
          label="*Tipo do Titulo"
          v-model="component.object.type_title"
          :items="title"
          outlined
          required
          :rules="[
            () => !!component.object.type_title || 'Campo Obrigatorio',
            () =>
              (!!component.object.type_title &&
                component.object.type_title.length >= 2) ||
              'Campo tem que ser maior do que 2',
          ]"
        ></v-select>
      </v-col>
      <v-col>
        <v-text-field
        :readonly="component.justVisible"
          label="*Numero do Titulo"
          v-model="component.object.number_title"
          outlined
          maxlength="15"
          counter="15"
          required
          :rules="[
            () => !!component.object.number_title || 'Campo Obrigatorio',
            () =>
              (!!component.object.number_title &&
                component.object.number_title.length >= 0) ||
              'Campo tem que ser maior do que 0',
          ]"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
        :readonly="component.justVisible"
          label="*Clientes"
          readonly
          append-outer-icon="mdi-plus-circle"
          @click:append-outer="showRegisterClientDialog"
          append-icon="mdi-magnify"
          @click:append="dialog = !dialog"
          v-model="component.object.client.people_name"
          outlined
          required
          :rules="[
            () => !!component.object.client.people_name || 'Campo Obrigatorio',
            () =>
              (!!component.object.client.people_name &&
                component.object.client.people_name.length >= 1) ||
              'Campo tem que ser maior do que 1',
          ]"
        ></v-text-field>
      </v-col>
    </v-form>
    <v-dialog max-width="800" persistent v-model="dialog">
      <v-card>
        <v-container>
          <v-card-title>Clientes</v-card-title>
          <v-switch
            v-model="isFormalPeople"
            label="Pessoa Jurídica"
            color="primary"
          >
          </v-switch>
          <v-text-field
            outlined
            label="Pesquisar"
            append-icon="mdi-magnify"
            v-model="search"
          ></v-text-field>
          <v-divider></v-divider>
          <v-data-table
            :headers="atual.header"
            :items="atual.getter"
            :search="search"
            hide-default-footer
            @click:row="rowClicked"
            no-data-text="Sem registros"
          ></v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="success" @click="dialog = !dialog">Fechar</v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <RegisterClient :out_of_register="true" />
  </div>
</template>

<script>
import { headers } from "./script";
import { mapGetters } from "vuex";
import { GET_ALL_CLIENT } from "../../../../store/actions/client";
import RegisterClient from "../../../Client/AddDialog";

export default {
  props: {
    component: Object,
  },
  components: {
    RegisterClient,
  },
  computed: {
    ...mapGetters(["getPhysicalClients", "getLegalClients"]),
  },

  data() {
    return {
      status: ["Aberto", "Baixado", "Renegociado", "Cancelado"],
      isFormalPeople: false,
      title: ["Boleto", "Depósito Bancário", "Dinheiro"],
      dialog: false,
      valid: true,
      headers,
      createInstanceVue: 0,
      search: "",
      getter: Array,
      atual: {
        header: Array(),
        getter: Array(),
      },
      pagPhysicalTotal: Number(),
      pagPhysicalCurrent: Number(),

      pagLegalTotal: Number(),
      pagLegalCurrent: Number(),

      atualCurrent: Number(),
      atualTotal: Number(),
    };
  },

  methods: {
    showRegisterClientDialog() {
      this.$store.dispatch("setShowDialogRegisterClient", true);
    },
    rowClicked(row) {
      if (row.type_people == "PhysicalPeople") {
        // this.component.object.client.people_name = row.name;
        this.component.object.client.people_name = row.physicalpeople.name;
      } else {
        this.component.object.client.people_name = row.legalpeople.fantasyName;
      }
      this.component.object.client_id = row.id;
      this.component.object.client.type_people = row.type_people;
      //this.dialog = !this.dialog;
      this.dialog = false;
    },
    leadFieldClient(row) {
      if (row.type_people == "PhysicalPeople") {
        // this.component.object.client.people_name = row.name;
        this.component.object.client.people_name = row.name;
      } else {
        this.component.object.client.people_name = row.fantasyName;
      }
      this.component.object.client_id = row.id;
      this.component.object.client.type_people = row.type_people;
      //this.dialog = !this.dialog;
    },
    pagPhysical(total, current) {
      this.atualTotal = total;
      this.atualCurrent = current;
    },
    isLegal(isLegal = false) {
      const type = { isPhisical: "isPhisical", isLegal: "isLegal" };
      this.atual.header =
        this.headers[isLegal ? type.isLegal : type.isPhisical];
      this.atual.getter = isLegal
        ? this.getLegalClients
        : this.getPhysicalClients;
      this.atualTotal = isLegal ? this.pagLegalTotal : this.pagPhysicalTotal;
      this.atualCurrent = isLegal
        ? this.pagLegalCurrent
        : this.pagPhysicalCurrent;
    },
    async GetAllPhysical(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        type_people: "PhysicalPeople",
        page,
        limit: 10,
        paginate: true,
      });
    },
    async GetAllLegal(page = 1) {
      await this.$store.dispatch(GET_ALL_CLIENT, {
        type_people: "LegalPeople",
        page,
        limit: 10,
        paginate: true,
      });
    },
  },

  created() {
    this.GetAllPhysical();
    this.GetAllLegal();
  },

  watch: {
    atualCurrent: function (val) {
      if (this.isFormalPeople) {
        this.GetAllLegal && this.GetAllLegal(val);
      } else {
        this.GetAllPhysical && this.GetAllPhysical(val);
      }
    },

    valid: function () {
      if (this.valid == true) {
        this.component.Validation_form.tab_Generate_Data = this.valid;
      }
    },
  },

  mounted() {
    this.isLegal();

    this.$watch(
      () => this.isFormalPeople,
      function (val) {
        this.isLegal(val);
      }
    );
  },
};
</script>

