<template>
  <div>
    <v-form v-model="component.accountingForm">
      <v-col>
        <v-select
        :readonly="component.justVisible"
          label="*Plano de Contas"
          v-model="component.plan"
          :items="component.accounting_plan"
          :append-outer-icon="icons[0]"
          @click:append-outer="showDialogAccountPlan"
          item-value="id"
          item-text="description"
          outlined
          :rules="[() => !!component.plan || 'Campo Obrigatorio']"
        ></v-select>
      </v-col>
    </v-form>
    <AccountPlanDialog/>
  </div>
</template>

<script>
import { mdiPlusCircle } from '@mdi/js';
import AccountPlanDialog from '../../../account_plans/AddDialog'
export default {
  components:{AccountPlanDialog},
  props: {
    component: Object,
  },
  data() {
    return {
      icons:[mdiPlusCircle],
      valid: true,
    };
  },
  methods:{
    showDialogAccountPlan(){
      this.$store.dispatch("setShowDialogRegisterAccountPlan",true)
    }
  },
  watch: {
    valid: function () {
      if (this.valid == true) {
        this.component.Validation_form.tab_Accounting = this.valid;
      }
    },
  },
};
</script>

<style>
</style>